<template>
  <div class="main">
    <div class="top-contianer" :style="topStyle">
      <div class="big-logo-container" v-if="showBigLogo">
        <div class="big-logo">
          <img
            src="@/assets/img/big-egg-logo.png"
            alt="DANDAN NETWORK TECHNOLOGY CO.,LTD."
            width="450"
            height="83"
          />
        </div>
        <div class="logo-font-container">
          <span class="red-font">DANDAN&nbsp;</span>
          NETWORK&nbsp;TECHNOLOGY&nbsp;CO.,LTD.
        </div>
      </div>
      <div class="small-logo-container" v-else>
        <img
          src="@/assets/img/big-egg-logo.png"
          alt="DANDAN NETWORK TECHNOLOGY CO.,LTD."
          width="336"
          height="62"
        />
      </div>
    </div>
    <div class="bottom-container">
      <div class="tabs-container">
        <div class="tabs-list">
          <div
            class="tabs-item"
            @mouseover="hoverIcon($event)"
            @mouseout="leaveIcon()"
            @click="clickLink($event)"
            data-index="0"
          >
            首页
          </div>
          <div
            class="tabs-item"
            @mouseover="hoverIcon($event)"
            @mouseout="leaveIcon()"
            @click="clickLink($event)"
            data-index="1"
          >
            关于
          </div>
          <div
            class="tabs-item"
            @mouseover="hoverIcon($event)"
            @mouseout="leaveIcon()"
            @click="clickLink($event)"
            data-index="2"
          >
            招聘
          </div>
          <div
            class="tabs-item"
            @mouseover="hoverIcon($event)"
            @mouseout="leaveIcon()"
            @click="clickLink($event)"
            data-index="3"
          >
            联系
          </div>
          <div class="icon-container" :style="style">
            <img
              src="@/assets/img/s.png"
              alt="DANDAN NETWORK TECHNOLOGY CO.,LTD."
              width="16"
            />
          </div>
        </div>
      </div>
      <div class="content-container">
        <div class="content-item" v-if="hoverIndex == 1">
          <div class="about-img">
            <img src="@/assets/img/about.jpg" alt="关于我们" />
          </div>
          <div class="about-font">
            <p>
              汕头市蛋蛋网络科技有限公司成立于2020年，是一家集合研发，技术外包的网络科技公司，
            </p>
            <p>
              组建了一支拥有核心技术开发能力并具有丰富网络经验的现代型企业团队，公司主要干部都是从事网站建设，软件开发，网络营销与推广。
            </p>
            <p>
              公司总部位于美丽的海滨城市中心汕头龙湖区，办公环境优雅，氛围轻松亲和，团队以充满活力的90后为主力军。
            </p>
            <img src="@/assets/img/text.jpeg" alt="text" />
          </div>
        </div>
        <div class="content-item" v-if="hoverIndex == 2">
          <div class="about-img">
            <img src="@/assets/img/recut.jpg" alt="招聘" />
          </div>
          <div class="recruit-list">
            <div class="recruit-item">
              <div class="recruit-item-icon">
                <img src="@/assets/img/ui.png" alt="设计（PS / CDR / UI）" />
              </div>
              <div class="recruit-title">设计（PS / CDR / UI）</div>
            </div>
            <div class="recruit-item">
              <div class="recruit-item-icon">
                <img src="@/assets/img/qianduan.png" alt="前端（HTML / CSS / JAVASCRIPT）" />
              </div>
              <div class="recruit-title">前端（HTML / CSS / JAVASCRIPT）</div>
            </div>
            <div class="recruit-item">
              <div class="recruit-item-icon">
                <img src="@/assets/img/houduan.png" alt="后端（C# / PHP / C++ / JAVA）" />
              </div>
              <div class="recruit-title">后端（C# / PHP / C++ / JAVA）</div>
            </div>
            <div class="recruit-item">
              <div class="recruit-item-icon">
                <img src="@/assets/img/mobile.png" alt="移动端（ANDROID / OC / SWIFT）" />
              </div>
              <div class="recruit-title">移动端（ANDROID / OC / SWIFT）</div>
            </div>
          </div>
          <!-- <div class="email-container">
            Please send your resume to job@91funny.com
          </div> -->
        </div>
        <div class="content-item" v-if="hoverIndex == 3">
          <div class="about-img">
            <img src="@/assets/img/contact.jpeg" alt="联系" />
          </div>
          <div class="map-img-container">
            <img src="@/assets/img/egg-map.png" alt="联系我们">
          </div>
          <div class="recruit-title">
            ADD：汕头市龙湖区金砂东路120号奋发园主楼301之323
          </div>
        </div>
      </div>
      <div :class="['web-bottom', hoverIndex > 0 ? 'border' : '']">
        <p>
          &copy;2020-2023&nbsp;汕头市蛋蛋网络科技有限公司
          <!-- <a href="http://beian.miit.gov.cn" class="icp-link"
            >粤ICP备 2020090465号
          </a> -->
          <img
            src="@/assets/img/egg-s-small-logo.png"
            alt="广州水熊互娱科技有限公司"
            width="54"
            class="small-logo"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageIndex",
  data() {
    return {
      style: {},
      topStyle: {},
      hoverIndex: 0,
      showBigLogo: true,
    };
  },
  methods: {
    setIconLeft(index) {
      const style = index * 108 + 46; //108为元素宽度 46是初始三角形left的数值
      this.$data.style = { left: `${style}px` };
    },
    hoverIcon(e) {
      const index = e.currentTarget.dataset.index;
      this.setIconLeft(index);
    },
    leaveIcon() {
      this.setIconLeft(this.$data.hoverIndex);
    },
    clickLink(e) {
      const index = e.currentTarget.dataset.index;

      this.$data.hoverIndex = index;
      if (index != 0) {
        this.$data.topStyle = { height: "100px" };
        this.$data.showBigLogo = false;
      } else {
        this.$data.topStyle = { height: "600px" };
        this.$data.showBigLogo = true;
      }

      this.setIconLeft(index);
    },
  },
};
</script>

<style lang="less">
@import "@/assets/css/static.less";

.top-contianer {
  position: relative;
  height: 600px;
  text-align: center;
  overflow: hidden;
  background: #000;
  transition: height 0.3s;
}

.small-logo-container {
  width: 336px;
  height: 62px;
  margin: 20px auto 0 auto;
}

.tabs-container {
  background: #000;
  height: 38px;
  line-height: 32px;
  text-align: center;
}

.web-bottom {
  padding: 70px 0 30px 0;
  color: #333;
  text-align: center;
}

.small-logo {
  // margin-left: 10px;
  margin: -2px 0 0 10px;
  vertical-align: middle;
}

.icp-link {
  color: #999;
  transition: color 0.3s;
}

.icp-link:hover {
  color: #333;
}

.big-logo-container {
  width: 450px;
  height: 83px;
  margin: 260px auto 0 auto;
}

.logo-font-container {
  margin-top: 50px;
  font-size: 15px;
  color: #fff;
}

.red-font {
  color: #e1794e;
  font-size: 15px;
}

.tabs-list {
  width: 432px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  height: 38px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabs-item {
  width: 108px;
  font-size: 14px;
  height: 38px;
  cursor: pointer;
  position: relative;
  z-index: 99;
}

.icon-container {
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 2;
  bottom: -1px;
  left: 46px;
  transition: all 0.3s;
}

.content-container {
  overflow: hidden;
}

.content-item {
  width: 1190px;
  margin: 50px auto 180px auto;
}

.about-img {
  margin-bottom: 20px;
}

.about-font {
  width: 1000px;
  margin: 0 auto;
  text-align: center;
  p {
    text-indent: 2em;
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
    line-height: 30px;
  }
}

.border {
  border-top: 1px solid #333;
}

.recruit-list {
  width: 1190px;
  display: flex;
  justify-content: space-around;
}

.recruit-title {
  font-size: 14px;
  color: #333;
  margin-top: 15px;
  text-align: center;
}

.email-container {
  height: 20px;
  line-height: 20px;
  color: #e1794e;
  font-size: 14px;
  margin-top: 50px;
}

.recruit-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.recruit-item-icon{
  text-align: center;
}

.map-img-container{
  text-align: center;
}
</style>
